jQuery(document).ready(function ($) {

    /* Scroll to section */
    if ($('.h-nav-link').length !== 0) {
        $('.h-nav-link').on('click', function (e) {
            e.preventDefault();

            var id = this.getAttribute('href');
            $('html, body').animate({
                scrollTop: $(id).offset().top
            }, 1000);
        });
    }

    /* Colored header */
    $(window).scroll(function () {
        var header = $('.header');

        if ($(window).scrollTop() > 50) {
            header.addClass('header--colored');
        } else {
            header.removeClass('header--colored');
        }
    });

    /* Popups */
    (function () {
        var login = document.querySelector('.h-login');
        var signUp = document.querySelector('.h-signup');
        var signBody = document.querySelector('.signup-btn');
        var signBanner = document.querySelector('.banner__signup');
        var getStarted = document.querySelector('.price-table__link');

        function showPopup(event) {
            event.preventDefault();
            var targetData = event.target.dataset.popup;
            var popup = document.getElementById('popup-' + targetData);

            $(popup).fadeIn(500, function () {
                $(popup).find('.popup__close').on('click', closePopup);
            })
        }

        function closePopup(event) {
            var el = event.target;
            $(el).off('click');
            $(el).parents('.popup-wr').fadeOut();
        }

        if (login && login.length !== 0) {
            login.addEventListener('click', showPopup);
            signUp.addEventListener('click', showPopup);
        }
        if (signBody) {
            signBody.addEventListener('click', showPopup);
            signBanner.addEventListener('click', showPopup);
        }

        if (getStarted) {
            $('body').on('click', '.price-table__link', function (event) {
                const subscriptionType = $(this).attr('data-type');
                $.ajax({
                    url: '/user/check-auth',
                    data: {subscription_type: subscriptionType},
                    type: 'POST',
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
                    },
                    success: function (response) {
                        if (!response.success) {
                            showPopup(event);
                        } else if (response.url) {
                            window.location.href = response.url;
                        }
                    }
                });
            });
        }
    })();

    /* Info popups */
    // (function() {
    //     var infoLink = $('.footer__info-link');
    //
    //     function closeInfoPopup(event) {
    //         $(event.target).parents('.info-popup').fadeOut();
    //         $(event.target).off('click');
    //     }
    //
    //     function openInfoPopup(event) {
    //         event.preventDefault();
    //
    //         var dataVal = event.target.dataset.info;
    //         var popup = $('#info-' + dataVal);
    //         popup.fadeIn(500, function(){
    //            popup.find('.close-info').on('click', closeInfoPopup);
    //         });
    //     }
    //
    //     if(infoLink.length !== 0) {
    //         infoLink.on('click', openInfoPopup);
    //     }
    // })();

    /* Mobile menu */
    (function () {
        var btn = document.querySelector('.burger'),
            header = document.querySelector('.main-header-wr'),
            mobLine = document.querySelector('.m-header');

        function toggleMenu() {
            if (!btn.classList.contains('burger--active')) {
                btn.classList.add('burger--active');
                mobLine.classList.add('m-header--open');
                $(header).slideDown();
            } else {
                btn.classList.remove('burger--active');
                $(header).slideUp();
                mobLine.classList.remove('m-header--open');
            }
        }

        if (btn) {
            btn.addEventListener('click', toggleMenu);

            $(window).resize(function () {
                mobLine.classList.remove('m-header--open');
                btn.classList.remove('burger--active');
                header.removeAttribute('style');
            })
        }
    })();

});
